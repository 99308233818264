var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Flex, Typography, Avatar } from '@robinpowered/ui-kit';
import AvatarImgBen from '../../../../images/Avatar_BenHarper.png';
import AvatarImgGibran from '../../../../images/Avatar_GibranSimmons.png';
import AvatarImgEileen from '../../../../images/Avatar_EileenZhou.png';
import CoverImageActivity from '../../../../images/CoverImage_Activity.png';
import { useTranslation } from 'react-i18next';
export var ActivityCard = function () {
    var t = useTranslation('downloadMobileAppModal').t;
    return (_jsxs(Card, { children: [_jsx(CoverImage, { src: CoverImageActivity }), _jsx(Typography.Paragraph, { style: { marginBottom: '6px', textAlign: 'left' }, children: t('activity_card_title') }), _jsxs(AvatarWrapper, { children: [_jsx(Avatar, { name: 'Ben Harper', size: 24, src: AvatarImgBen }), _jsx(Avatar, { name: 'Gibran Simmons', size: 24, src: AvatarImgGibran }), _jsx(Avatar, { name: 'Eileen Zhou', size: 24, src: AvatarImgEileen })] }), _jsx(ActivityCardButton, { children: t('activity_card_button') })] }));
};
var Card = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  border: 1px solid #cbc9c9;\n  border-radius: 8px;\n  padding: 6px;\n  max-width: fit-content;\n  background-color: white;\n  margin-right: -100px;\n  margin-left: 72px;\n  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);\n"], ["\n  position: absolute;\n  border: 1px solid #cbc9c9;\n  border-radius: 8px;\n  padding: 6px;\n  max-width: fit-content;\n  background-color: white;\n  margin-right: -100px;\n  margin-left: 72px;\n  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);\n"])));
var CoverImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  aspect-ratio: 3 / 1;\n  width: 165px;\n  height: 56px;\n  object-fit: cover;\n  object-position: center top;\n  margin-top: -6px;\n  margin-right: -6px;\n  margin-left: -6px;\n  margin-bottom: 6px;\n  border-top-left-radius: 8px;\n  border-top-right-radius: 8px;\n"], ["\n  aspect-ratio: 3 / 1;\n  width: 165px;\n  height: 56px;\n  object-fit: cover;\n  object-position: center top;\n  margin-top: -6px;\n  margin-right: -6px;\n  margin-left: -6px;\n  margin-bottom: 6px;\n  border-top-left-radius: 8px;\n  border-top-right-radius: 8px;\n"])));
var AvatarWrapper = styled(Flex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-direction: row;\n  gap: 2px;\n  margin-bottom: 6px;\n"], ["\n  flex-direction: row;\n  gap: 2px;\n  margin-bottom: 6px;\n"])));
var ActivityCardButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid #cbc9c9;\n  border-radius: 4px;\n  padding: 6px;\n  font-size: 10px;\n  font-weight: 700;\n"], ["\n  border: 1px solid #cbc9c9;\n  border-radius: 4px;\n  padding: 6px;\n  font-size: 10px;\n  font-weight: 700;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
