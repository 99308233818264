var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useClickAway, useKey } from 'react-use';
import { MenuSelect } from '../../components';
import { useHeaderContext } from '../../contexts';
import { AvatarButton } from './AvatarButton';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useFeatureFlag } from '../../hooks/useFeatureFlagService';
var useMenuItems = function () {
    var _a;
    var t = useTranslation('userProfileMenu').t;
    var _b = useHeaderContext(), activeOrg = _b.activeOrg, currentUser = _b.currentUser;
    var orgSlug = (_a = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.slug) !== null && _a !== void 0 ? _a : '';
    var showWorkplaceDashboardHeaderLink = useFeatureFlag({
        flag: 'workplace-dashboard(show-header-link)',
        defaultValue: false,
        tenantId: (activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.id) || null,
    }).value;
    var menuItems = [
        {
            label: t('profile'),
            href: "/".concat(orgSlug, "/people/").concat(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id),
        },
        {
            label: t('userSettings'),
            href: '/account',
        },
        {
            label: t('help'),
            items: [
                {
                    label: t('helpMenu.helpCenter'),
                    href: 'https://support.robinpowered.com',
                },
                {
                    label: t('helpMenu.contactUs'),
                    href: "/".concat(orgSlug, "/premier-support"),
                },
                {
                    label: t('helpMenu.submitRequest'),
                    divider: true,
                    href: 'mailto:support@robinpowered.com',
                },
                {
                    label: t('helpMenu.status'),
                    href: 'https://status.robinpowered.com',
                },
            ],
        },
        {
            label: t('helpMenu.productUpdates'),
            href: 'https://support.robinpowered.com/hc/en-us/categories/32826409804173-Product-updates',
            external: true,
        },
        {
            label: t('getApps'),
            divider: true,
            href: 'https://robinpowered.com/downloads',
        },
        {
            label: t('signOut'),
            href: '/logout',
        },
    ];
    if (showWorkplaceDashboardHeaderLink) {
        menuItems.splice(1, 0, {
            label: t('workplaceDashboard'),
            href: "/".concat(orgSlug, "/workplace-dashboard"),
        });
    }
    return menuItems;
};
var RelativeContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var UserProfileMenu = function () {
    var _a = useHeaderContext(), isLoading = _a.isLoading, currentUser = _a.currentUser;
    var _b = __read(useState(false), 2), menuOpen = _b[0], setMenuOpen = _b[1];
    var menuItems = useMenuItems();
    var t = useTranslation('userProfileMenu').t;
    var menuRef = useRef(null);
    useClickAway(menuRef, function () {
        setMenuOpen(false);
    });
    useKey('Escape', function () { return setMenuOpen(false); });
    if (isLoading) {
        return null;
    }
    var onItemClick = function (item) {
        // We need to keep the menu open so Launchnotes popup can position itself properly
        // Menu will close when clicking away from helpMenu.productUpdates menu item
        if (item.label !== t('helpMenu.productUpdates')) {
            setMenuOpen(false);
        }
    };
    return (_jsxs(RelativeContainer, { ref: menuRef, children: [_jsx(AvatarButton, { isOpen: menuOpen, avatar: currentUser === null || currentUser === void 0 ? void 0 : currentUser.avatar, userName: currentUser === null || currentUser === void 0 ? void 0 : currentUser.name, onClick: function () { return setMenuOpen(function (open) { return !open; }); } }), _jsx(MenuSelect, { isOpen: menuOpen, items: menuItems, onItemClick: onItemClick })] }));
};
var templateObject_1;
