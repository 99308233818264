var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Checkmark } from '../../components';
var Avatar = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  height: 25px;\n  width: 25px;\n  border-radius: 50%;\n  margin-right: 10px;\n"], ["\n  display: inline-block;\n  height: 25px;\n  width: 25px;\n  border-radius: 50%;\n  margin-right: 10px;\n"])));
var OrgMenuItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  div {\n    text-align: start;\n  }\n\n  img {\n    vertical-align: middle;\n  }\n\n  path {\n    stroke-width: 4px;\n    stroke: #550331;\n  }\n\n  :hover path {\n    stroke-width: 4px;\n    stroke: #ffffff;\n  }\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  div {\n    text-align: start;\n  }\n\n  img {\n    vertical-align: middle;\n  }\n\n  path {\n    stroke-width: 4px;\n    stroke: #550331;\n  }\n\n  :hover path {\n    stroke-width: 4px;\n    stroke: #ffffff;\n  }\n"])));
var OrgName = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 182px;\n  display: inline-block;\n  vertical-align: middle;\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 182px;\n  display: inline-block;\n  vertical-align: middle;\n"])));
export function buildUserOrgMenu(orgs, path, activeOrg) {
    return orgs.map(function (org) {
        var _a;
        return ({
            label: (_a = org.name) !== null && _a !== void 0 ? _a : '',
            href: "/".concat(org.slug).concat(path),
            renderElement: (_jsxs(OrgMenuItem, { children: [_jsxs("div", { children: [org.avatar && _jsx(Avatar, { src: org.avatar, alt: "" }), _jsx(OrgName, { children: org.name })] }), (activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.id) === org.id && _jsx(Checkmark, { width: 11, height: 11 })] })),
        });
    });
}
var templateObject_1, templateObject_2, templateObject_3;
