var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo, useEffect } from 'react';
import { useHttpState } from './useHttpState';
import { useAccessToken } from './useAccessToken';
import { useHeaderContext } from '../contexts';
export var FeatureFlag;
(function (FeatureFlag) {
})(FeatureFlag || (FeatureFlag = {}));
var createUnleashClient = function (baseURL) {
    return {
        getFlags: function (accessToken_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([accessToken_1], __read(args_1), false), void 0, function (accessToken, tenantId) {
                var params, data, response;
                if (tenantId === void 0) { tenantId = null; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = {
                                accessToken: accessToken,
                                userProperties: JSON.stringify({
                                    orgId: tenantId ? tenantId : undefined,
                                }),
                            };
                            data = Object.keys(params)
                                .map(function (key) { return "".concat(key, "=").concat(encodeURIComponent(params[key])); })
                                .join('&');
                            return [4 /*yield*/, fetch("".concat(baseURL), {
                                    method: 'POST',
                                    headers: {
                                        'content-type': 'application/x-www-form-urlencoded',
                                    },
                                    body: data,
                                })];
                        case 1:
                            response = _a.sent();
                            if (!response.ok) {
                                throw new Error("Failed to fetch feature flags: ".concat(response.status, " ").concat(response.statusText));
                            }
                            return [2 /*return*/, response.json()];
                    }
                });
            });
        },
    };
};
var useUnleashClient = function (baseUrl) {
    return useMemo(function () { return createUnleashClient(baseUrl); }, [baseUrl]);
};
var useFeatureFlags = function (serviceUrl, accessToken, tenantId) {
    var client = useUnleashClient(serviceUrl);
    var _a = useHttpState(), data = _a.data, error = _a.error, loading = _a.loading, setLoading = _a.setLoading, setData = _a.setData, setError = _a.setError;
    useEffect(function () {
        setLoading();
        var controller = new AbortController();
        client
            .getFlags(accessToken, tenantId)
            .then(function (response) {
            setData(response);
        })
            .catch(function (error) {
            setData(null);
            setError(error);
        });
        return function () { return controller.abort(); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, accessToken]);
    return {
        data: data,
        error: error,
        loading: loading,
    };
};
export var useFeatureFlag = function (_a) {
    var flag = _a.flag, _b = _a.defaultValue, defaultValue = _b === void 0 ? false : _b, _c = _a.tenantId, tenantId = _c === void 0 ? null : _c;
    var accessToken = useAccessToken().accessToken;
    var appConfig = useHeaderContext().appConfig;
    var _d = useFeatureFlags((appConfig === null || appConfig === void 0 ? void 0 : appConfig.featureFlagUrl) || '', accessToken || '', tenantId), data = _d.data, error = _d.error, loading = _d.loading;
    var value = error
        ? defaultValue // Error's presence means we want to return default value.
        : data && typeof data[flag] !== 'undefined'
            ? data[flag]
            : defaultValue;
    return {
        value: value,
        error: error,
        loading: loading,
    };
};
