var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Flex, Typography } from '@robinpowered/ui-kit';
import OfficeSpace from '../../../../images/OfficeSpace.png';
import GroupSolid from '../../Icons/GroupSolid';
import PhoneSolid from '../../Icons/PhoneSolid';
import VideoCallSolid from '../../Icons/VideoCallSolid';
import { useTranslation } from 'react-i18next';
export var SpaceItem = function (_a) {
    var name = _a.name;
    var t = useTranslation('downloadMobileAppModal').t;
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, { style: { marginBottom: '5px', marginTop: '6px' }, children: [_jsxs(Flex, { vertical: true, children: [_jsx(Typography.Paragraph, { style: {
                                    marginBottom: '2px',
                                    fontWeight: '400',
                                    textAlign: 'left',
                                }, children: name }), _jsxs(Flex, { gap: 2, style: { width: '155px' }, children: [_jsx(VideoCallSolid, { width: 13, height: 13, color: '#706E6E' }), _jsx(GroupSolid, { width: 13, height: 13, color: '#706E6E' }), _jsx(PhoneSolid, { width: 13, height: 13, color: '#706E6E' }), _jsx(Label, { children: t('space_item_label') })] })] }), _jsx(SpaceImage, { src: OfficeSpace })] }), _jsx(BookSpaceButton, { children: t('space_item_button') })] }));
};
var SpaceImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 45px;\n"], ["\n  width: 45px;\n"])));
var Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 8px;\n  color: #706e6e;\n  background-color: #f8f6f4;\n  border-radius: 2px;\n  padding: 1px 2px 1px 4px;\n  margin-left: 2px;\n"], ["\n  font-size: 8px;\n  color: #706e6e;\n  background-color: #f8f6f4;\n  border-radius: 2px;\n  padding: 1px 2px 1px 4px;\n  margin-left: 2px;\n"])));
var BookSpaceButton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid #cbc9c9;\n  border-radius: 4px;\n  padding: 5px 8px 5px;\n  width: 40px;\n  font-size: 8px;\n  font-weight: 500;\n"], ["\n  border: 1px solid #cbc9c9;\n  border-radius: 4px;\n  padding: 5px 8px 5px;\n  width: 40px;\n  font-size: 8px;\n  font-weight: 500;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
