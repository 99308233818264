var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ChevronDown } from '../Icons';
var Icon = styled(ChevronDown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transform: rotate(", ");\n  margin-left: 8px;\n  width: 8px;\n  height: 8px;\n\n  path {\n    stroke: #484747;\n    stroke-width: 8px;\n  }\n"], ["\n  transform: rotate(", ");\n  margin-left: 8px;\n  width: 8px;\n  height: 8px;\n\n  path {\n    stroke: #484747;\n    stroke-width: 8px;\n  }\n"])), function (props) { return (props.open ? '180deg' : '0deg'); });
export var DropdownIcon = function (_a) {
    var open = _a.open;
    return _jsx(Icon, { open: open });
};
var templateObject_1;
