import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Notifications } from '../../components';
import { useHeaderContext } from '../../contexts';
var NotificationWrapper = styled.a({
    position: 'relative',
    display: 'flex',
    padding: '3px',
    marginRight: '8px',
    borderRadius: '50%',
    border: '2px solid transparent',
    '&:hover': {
        backgroundColor: '#fbfaf9',
    },
    '&:focus-visible': {
        borderColor: '#550331',
    },
});
var Badge = styled('span')({
    position: 'absolute',
    padding: '3px',
    borderRadius: '50%',
    top: '7px',
    right: '7px',
    backgroundColor: '#E81C1C',
    border: "1px solid #FFFFFF",
    '&:hover': {
        border: "1px solid #fbfaf9",
        background: '#fbfaf9',
    },
}, function (props) { return ({
    display: props.hasNotifications ? 'inline' : 'none',
}); });
export var NotificationMenuItem = function () {
    var _a = useHeaderContext(), activeOrg = _a.activeOrg, userPermissions = _a.userPermissions;
    var _b = userPermissions || {}, canApproveSpaceRequests = _b.canApproveSpaceRequests, totalPendingNotifications = _b.totalPendingNotifications;
    if (!canApproveSpaceRequests || !activeOrg) {
        return null;
    }
    return (_jsxs(NotificationWrapper, { href: "/".concat(activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.slug, "/notifications"), children: [_jsx(Notifications, { height: "27px", width: "27px" }), _jsx(Badge, { hasNotifications: totalPendingNotifications })] }));
};
