//NOTE: The commented out code is for the location picker which we will add back once we want that feature
import styled from '@emotion/styled';
import {
  DeliveriesCreationDrawer,
  DeliveriesTable,
  DeliveriesTablePagination,
  DeliveryDrawer,
} from './components';
// import { useBestLocation, useGetDeliveryLocations } from './hooks';
import { useDeliveriesTableContext } from './contexts';
import { Colors, Column } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Row } from '@robinpowered/ui-kit';
import { useState } from 'react';

export const DeliveriesPage = () => {
  const { t } = useTranslation('deliveriesPage');
  const [showNewDeliveryDrawer, setShowNewDeliveryDrawer] = useState(false);
  // const { locationsData } = useGetDeliveryLocations();
  const { messageContextHolder, modalContextHolder } =
    useDeliveriesTableContext();
  // const { setParams } = useQueryParams();
  // const preferredLocation = useBestLocation(locationsData?.locations);
  // const [selectedLocation, setSelectedLocation] = useState<
  //   string | undefined
  // >();

  // useEffect(() => {
  // setSelectedLocation(preferredLocation?.name);
  // setParams({ location: preferredLocation?.id });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [preferredLocation?.name]);

  // const options = useMemo(() => {
  //   return locationsData?.locations?.map((location) => ({
  //     value: location.id,
  //     label: location.name,
  //   }));
  // }, [locationsData?.locations]);

  // function onLocationChange(value: string) {
  //   setParams({ location: value });
  // }

  return (
    <>
      {messageContextHolder}
      {modalContextHolder}

      <ScrollWrapper>
        <DeliveriesTableWrapper>
          <CardTitleWrapper>
            <Column>
              <CardTitle>{t('sidebar.title')}</CardTitle>
              <CardByline>{t('tableCard.byline')}</CardByline>
            </Column>
            <Button
              type="primary"
              onClick={() => setShowNewDeliveryDrawer(true)}
            >
              {t('tableCard.header_button')}
            </Button>
          </CardTitleWrapper>
          {/* <LocationWrapper>
            <Select
              options={options}
              loading={deliveryLocationsLoading}
              value={selectedLocation}
              placeholder={t('tableCard.location_placeholder')}
              onChange={onLocationChange}
          />
          </LocationWrapper> */}

          <DeliveriesTable />
          <DeliveriesTablePagination />
          <DeliveriesCreationDrawer
            showNewDeliveryDrawer={showNewDeliveryDrawer}
            setShowNewDeliveryDrawer={setShowNewDeliveryDrawer}
          />
          <DeliveryDrawer />
        </DeliveriesTableWrapper>
      </ScrollWrapper>
    </>
  );
};

const DeliveriesTableWrapper = styled.div`
  background-color: ${Colors.White0};
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${Colors.Gray10};
  padding: var(--Space-Margin-marginLG, 24px);
`;

const ScrollWrapper = styled.div`
  background-color: ${Colors.Gray5};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 32px;
`;

const CardTitle = styled(Typography.Title)`
  font-size: var(--Typography-Font-Size-fontSizeHeading1, 30px);
`;

const CardByline = styled(Typography.Text)`
  color: var(
    --Components-Typography-Global-colorTextDescription,
    rgba(0, 0, 0, 0.6)
  );
`;

const CardTitleWrapper = styled(Row)`
  padding-bottom: var(--Space-Margin-marginLG, 24px);
  justify-content: space-between;
  align-items: center;
`;

// const LocationWrapper = styled.div`
//   margin-bottom: 16px;
// `;
