var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ChevronDown } from '../Icons';
import { Avatar } from './Avatar';
var Button = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  background: none;\n  border: none;\n  padding: 3px 8px;\n  border: 2px solid transparent;\n  border-radius: 32px;\n\n  :hover {\n    background: #fbfaf9;\n  }\n\n  &:focus-visible {\n    border-color: #550331;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  background: none;\n  border: none;\n  padding: 3px 8px;\n  border: 2px solid transparent;\n  border-radius: 32px;\n\n  :hover {\n    background: #fbfaf9;\n  }\n\n  &:focus-visible {\n    border-color: #550331;\n  }\n"])));
var StyledChevronDown = styled(ChevronDown)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  transform: rotate(", ");\n  margin-left: 4px;\n  width: 8px;\n  height: 8px;\n\n  path {\n    stroke: #484747;\n    stroke-width: 8px;\n  }\n"], ["\n  transform: rotate(", ");\n  margin-left: 4px;\n  width: 8px;\n  height: 8px;\n\n  path {\n    stroke: #484747;\n    stroke-width: 8px;\n  }\n"])), function (props) { return (props.open ? '180deg' : '0deg'); });
export var AvatarButton = function (_a) {
    var isOpen = _a.isOpen, avatar = _a.avatar, userName = _a.userName, onClick = _a.onClick;
    return (_jsxs(Button, { type: "button", onClick: onClick, "aria-label": "User Dropdown", "aria-haspopup": "true", "aria-expanded": isOpen, children: [_jsx(Avatar, { src: avatar, name: userName !== null && userName !== void 0 ? userName : '', size: 32 }), _jsx(StyledChevronDown, { open: isOpen })] }));
};
var templateObject_1, templateObject_2;
