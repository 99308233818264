var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useHeaderContext } from '../contexts';
var APP_ID = process.env.REACT_APP_ROBIN_ENV === 'production'
    ? 'AP-DRJJ6AZGBVWT-2'
    : 'AP-DRJJ6AZGBVWT-2-2';
export var useGainsight = function () {
    var _a = useHeaderContext(), currentUser = _a.currentUser, activeOrg = _a.activeOrg, isLoading = _a.isLoading, error = _a.error, userPermissions = _a.userPermissions;
    var withEnv = function (str) {
        return process.env.REACT_APP_ROBIN_ENV !== 'production' ? "".concat(str, "-staging") : str;
    };
    var injectExternalScript = function (src) { return __awaiter(void 0, void 0, void 0, function () {
        var loadScript;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loadScript = function () {
                        return new Promise(function (resolve, reject) {
                            var _a;
                            var script = document.createElement('script');
                            script.src = src;
                            script.id = 'gainsight';
                            script.onload = function () { return resolve(); };
                            script.onerror = function () { return reject(); };
                            (_a = document.querySelector('body')) === null || _a === void 0 ? void 0 : _a.appendChild(script);
                        });
                    };
                    return [4 /*yield*/, loadScript()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!isLoading && !error) {
            // Prevent potential subsequent loads and inits
            if (document.querySelector('#gainsight') === null) {
                var src = "https://web-sdk.aptrinsic.com/api/aptrinsic.js?a=".concat(APP_ID);
                var i_1 = 'aptrinsic';
                var kvWindow_1 = window;
                kvWindow_1[i_1] = function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    (kvWindow_1[i_1].q = kvWindow_1[i_1].q || []).push(args);
                };
                kvWindow_1[i_1].p = APP_ID;
                injectExternalScript(src);
            }
            if (!currentUser ||
                !activeOrg ||
                !userPermissions ||
                !window.aptrinsic) {
                return;
            }
            window.aptrinsic('identify', {
                id: withEnv(currentUser.id),
                email: currentUser.primaryEmail
                    ? withEnv(currentUser.primaryEmail.email)
                    : null,
                UserType: userPermissions.canManagePlaces ? 'Admin' : 'Non Admin',
                FullName: withEnv(currentUser.name),
            }, {
                id: withEnv(activeOrg.id),
                name: withEnv(activeOrg.name),
            });
        }
    }, [isLoading, error, currentUser, activeOrg, userPermissions]);
};
