var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var SvgPhoneSolid = function (props) { return (_jsx("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.03464 4.01499C8.19882 4.05192 8.34073 4.15447 8.42731 4.29877L10.8883 8.40047C11.0336 8.6426 10.9955 8.95253 10.7958 9.1522L9.42292 10.5252C9.4513 10.586 9.488 10.6582 9.53535 10.7422C9.7651 11.1496 10.2397 11.8238 11.2069 12.7946C12.1736 13.7647 12.8469 14.2383 13.2539 14.4666C13.3387 14.5142 13.4115 14.5508 13.4727 14.579L14.8476 13.204C15.0472 13.0043 15.3572 12.9661 15.5993 13.1114L19.7012 15.5724C19.8455 15.659 19.9481 15.8009 19.985 15.9651C20.0219 16.1293 19.99 16.3015 19.8966 16.4415L18.256 18.9025C18.248 18.9145 18.2396 18.9261 18.2308 18.9375C17.7433 19.5683 16.9612 20.0507 16.0655 19.9957C9.93102 19.619 4.37849 14.0732 4.00423 7.93419C3.9494 7.03489 4.43473 6.25765 5.06059 5.77046C5.07248 5.76121 5.08471 5.75239 5.09724 5.74403L7.55828 4.10336C7.6983 4.01001 7.87045 3.97807 8.03464 4.01499Z", fill: props.color || '#0E1924' }) }))); };
export default SvgPhoneSolid;
