var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var SvgGroupSolid = function (props) { return (_jsx("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M14 8C14 10.2091 12.2091 12 10 12 7.79086 12 6 10.2091 6 8 6 5.79086 7.79086 4 10 4 12.2091 4 14 5.79086 14 8ZM2 20C2 16.4654 4.86538 13.6 8.4 13.6H11.6C11.9316 13.6 12.2573 13.6252 12.5752 13.6738 12.6695 14.1832 12.8495 14.6625 13.0998 15.0962 11.3475 16.0656 10.1316 17.8861 10.01 20H2ZM17 15C18.3807 15 19.5 13.8807 19.5 12.5 19.5 11.1193 18.3807 10 17 10 15.6193 10 14.5 11.1193 14.5 12.5 14.5 13.8807 15.6193 15 17 15ZM16 16C13.7909 16 12 17.7909 12 20H22C22 17.7909 20.2091 16 18 16H16Z", fill: props.color || '#0E1924' }) }))); };
export default SvgGroupSolid;
