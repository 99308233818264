var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var SvgVideoCallSolid = function (props) { return (_jsx("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M5 5C3.34315 5 2 6.34315 2 8V16C2 17.6569 3.34315 19 5 19H13C14.6569 19 16 17.6569 16 16V8C16 6.34315 14.6569 5 13 5H5ZM20.4 7.2C21.0592 6.70557 22 7.17595 22 8V16C22 16.824 21.0592 17.2944 20.4 16.8L18.4 15.3C18.1482 15.1111 18 14.8148 18 14.5V9.5C18 9.18524 18.1482 8.88885 18.4 8.7L20.4 7.2Z", fill: props.color || '#0E1924' }) }))); };
export default SvgVideoCallSolid;
