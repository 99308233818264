var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Typography } from '@robinpowered/ui-kit';
import { SpaceItem } from './SpaceItem';
import { ActivityCard } from './ActivityCard';
import { useTranslation } from 'react-i18next';
export var AddSpaceCard = function () {
    var t = useTranslation('downloadMobileAppModal').t;
    return (_jsxs(Card, { children: [_jsxs(ContentWrapper, { children: [_jsx(Typography.Paragraph, { style: { marginBottom: '20px', fontWeight: '500', textAlign: 'left' }, children: t('add_space_card_title') }), _jsx(SpaceItem, { name: t('space_item_name_1') }), _jsx(SpaceItem, { name: t('space_item_name_2') })] }), _jsx(ActivityCard, {})] }));
};
var Card = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid #cbc9c9;\n  border-radius: 8px;\n  padding: 11px;\n  max-width: fit-content;\n  height: 190px;\n  margin-left: -26px;\n  background-color: white;\n  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);\n"], ["\n  border: 1px solid #cbc9c9;\n  border-radius: 8px;\n  padding: 11px;\n  max-width: fit-content;\n  height: 190px;\n  margin-left: -26px;\n  background-color: white;\n  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);\n"])));
var ContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: -112px;\n"], ["\n  margin-bottom: -112px;\n"])));
var templateObject_1, templateObject_2;
