import { useTranslation } from 'react-i18next';
import { TableColumnsType } from '@robinpowered/ui-kit';
import { MOCK_RECIPIENTS, MOCK_ZONES } from '../constants';

type FilterDataTypes = {
  deliveryIds: string[];
};

export enum DeliveryStatus {
  pending = 'Pending',
  unknown = 'Unknown recipient',
  delivered = 'Picked up',
  missing = 'Missing package',
}

export const useDeliveriesTableData = (
  { deliveryIds }: FilterDataTypes,
  setDeliveryIdSearchTerm: (value: string) => void
) => {
  const { t } = useTranslation('deliveriesTable');

  // Note: at least one column should be left without a width to allow the table to remain fluid
  const columns: TableColumnsType = [
    {
      title: t('headers.id'),
      dataIndex: 'deliveryId',
      key: 'deliveryId',
      width: 200,
      filterSearch: (searchTerm) => {
        setDeliveryIdSearchTerm(searchTerm);
        return true;
      },
      filters: deliveryIds.map((id) => ({ text: id, value: id })),
      filterResetToDefaultFilteredValue: true,
      filteredValue: deliveryIds,
      onFilter: (value, record) => record.key === value,
    },
    {
      title: t('headers.recipient'),
      dataIndex: 'recipient',
      key: 'recipient',
      width: 240,
      sorter: true,
      sortOrder: undefined, // TODO: update once sorting is available
      filterSearch: true,
      filters: MOCK_RECIPIENTS,
      onFilter: () => true,
    },
    {
      title: t('headers.status'),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      sorter: true,
      sortOrder: undefined, // TODO: update once sorting is available
      filters: Object.keys(DeliveryStatus).map((s) => ({ text: s, value: s })),
      onFilter: () => true,
    },
    {
      title: t('headers.zone'),
      dataIndex: 'deliveryZone',
      key: 'deliveryZone',
      width: 200,
      sorter: true,
      sortOrder: undefined, // TODO: update once sorting is available
      filterSearch: true,
      filters: MOCK_ZONES,
      onFilter: () => true,
    },
    {
      title: t('headers.last_notified'),
      dataIndex: 'lastNotified',
      key: 'lastNotified',
      width: 155,
      sorter: true,
      sortOrder: undefined, // TODO: update once sorting is available
    },
    {
      title: t('headers.created_on'),
      dataIndex: 'createdOn',
      key: 'createdOn',
      width: 155,
      sorter: true,
      sortOrder: undefined, // TODO: update once sorting is available
    },
    {
      title: t('headers.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 155,
      fixed: 'right',
    },
  ];

  return {
    columns,
  };
};
